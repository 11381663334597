import './Home.css';
//assests import
import UG_logo from "../../assets/ug_logo.png"
import teahouse from "../../assets/tea-house.gif"
import matcha from "../../assets/matcha.jpg"
import loadingGif from "../../assets/loading.gif"

//libs imports
import React, { useState, useEffect } from "react";
import Web3 from "web3"
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../utils/connector"
import UGContractABI from "../../utils/UGContractABI.json"
import Airtable from "airtable"

const UG_CONTRACT_ADDRESS = "0xAD9947CC6A21a50EB5Ccd767471Dea354Bcf9282"

const Home = () => {

  const { active, account, library, connector, activate, deactivate } = useWeb3React()
  const [matchaBalance, setMatchaBalance] = useState(0);
  const [ugBalance, setUgBalance] = useState(0);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [currentSingleAPR, setCurrentSingleAPR] = useState(0);
  const [calculatedAPR, setCalculatedAPR] = useState(0);
  const [matchaRewardPeriod, setMatchaRewardPeriod] = useState(0);
  const [calculateEarnedMatchaReward, setCalculateEarnedMatchaReward] = useState(0);
  const [calculatedWhitelistedMatchaValue, setCalculatedWhitelistedMatchaValue] = useState(0);
  const [whitelistingOpen, setWhitelistingOpen] = useState(false);
  const [whitelistSaleOpen, setWhitelistSaleOpen] = useState(false);
  const [saleOpen, setSaleOpen] = useState(false);
  const [whitelistLoading, setWhitelistLoading] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [oneFreeClaimed, setOneFreeClaimed] = useState(false);
  const [isJoined, setIsJoined] = useState(0);
  const [inputData, setInputData] = useState();

  const handleChange = (e) => {
    const value = e.target.value;

    setInputData({ ...inputData, [e.target.name]: { "data": value, "errorText": "" } })
  }

  const connectWallet = async () => {
    try {
      await activate(injected)
      // loadContractProps()
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    document.getElementsByClassName("home-body-left")[0].style.backgroundImage = `url(${teahouse})`
    if (!active) { } else {
      loadContractProps()
      calculateWhitelistedMatchaValue()
    }
  })

  const airtablePush = async (_account, _twitter) => {
    var base = new Airtable({ apiKey: 'keySgVtuwHaF42Zld' }).base('app804oKd8AgAaCoR');

    base('0x5Be8DD31a91D28918fc4b0a169004632aC9Bd834').create([
      {
        "fields": {
          "wallet": _account,
          "twitter": _twitter
        }
      }
    ], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      // records.forEach(function (record) {
      //   console.log(record.getId());
      // });
    });
  }

  const loadContractProps = async () => {
    try {
      const contract = new library.eth.Contract(
        UGContractABI,
        UG_CONTRACT_ADDRESS
      );

      setMatchaBalance(Web3.utils.fromWei(await contract.methods
        .balanceOfMatchaReward(account)
        .call()))

      setUgBalance(await contract.methods
        .balanceOf(account)
        .call())

      setIsWhitelisted(await contract.methods
        .isWhitelisted(account)
        .call())

      setCurrentSingleAPR(await contract.methods
        .calculateMatchaRewardAPRSingle()
        .call())

      if (ugBalance > 0) {
        setCalculatedAPR(await contract.methods
          .calculateMatchaRewardAPR()
          .call({
            from: account
          }))
      }

      setWhitelistingOpen(await contract.methods
        .whitelistingOpen()
        .call())

      setWhitelistSaleOpen(await contract.methods
        .whitelistedSaleStarted()
        .call())

      setSaleOpen(await contract.methods
        .saleStarted()
        .call())

      if (ugBalance > 0) {
        setCalculateEarnedMatchaReward(Web3.utils.fromWei(await contract.methods
          .calculateMatchaReward(account, ugBalance)
          .call({
            from: account
          })))
      }

      setMatchaRewardPeriod(await contract.methods
        ._matchaAPRRewardPeriod(account)
        .call())

      setOneFreeClaimed(await contract.methods
        ._oneFreeClaimed(account)
        .call())

      setIsJoined(await contract.methods
        .isJoinedInMatchaReward(account)
        .call())

      console.log('isJoined ', isJoined)
      // console.log('whitelistSaleOpen ', whitelistSaleOpen)
      // console.log('saleOpen ', saleOpen)

    } catch (error) {
      console.error('ERROR LOADING CONTRACT PROPS: ', error)
    }
  }

  const calculateWhitelistedMatchaValue = async () => {
    if (currentSingleAPR > 0 && matchaRewardPeriod > 0) {
      let block = await library.eth.getBlock('latest');
      let matchaPerSecond = currentSingleAPR / 31556926;
      let diffPeriod = parseInt(block.timestamp) - parseInt(matchaRewardPeriod);
      let calc = diffPeriod * matchaPerSecond;
      let calX = Web3.utils.fromWei(calc.toString())
      setCalculatedWhitelistedMatchaValue(parseFloat(calX).toFixed(4))
    }
  }

  const whitelistUser = async (e) => {
    e.preventDefault();

    if (!inputData || inputData && inputData.twitter.data === "") {
      return false;
    }

    setWhitelistLoading(true)

    const contract = new library.eth.Contract(
      UGContractABI,
      UG_CONTRACT_ADDRESS
    );

    try {
      const receipt = await contract.methods
        .getWhitelisted().send({ from: account });

      airtablePush(account, inputData && inputData.twitter.data)

      setWhitelistLoading(false);
    } catch (error) {
      alert(error.message);
      setWhitelistLoading(false);
      console.error('CONTRACT SET ERROR: ', error)
    }
  }

  const whitelistMint = async (e) => {
    e.preventDefault();

    let mintPrice = await calculateWhitelistPrice()

    console.log('click mint ', await calculateWhitelistPrice())

    setMintLoading(true)

    const contract = new library.eth.Contract(
      UGContractABI,
      UG_CONTRACT_ADDRESS
    );

    try {
      const receipt = await contract.methods
        .whitelistedMint(mintAmount)
        .send({
          from: account,
          value: mintPrice
        });

      setMintLoading(false);
    } catch (error) {
      alert(error.message);
      setMintLoading(false);
      console.error('CONTRACT SET ERROR: ', error)
    }
  }

  const publicMint = async (e) => {
    e.preventDefault();

    let mintPrice = await calculatePublicPrice()

    console.log('click mint ', await calculatePublicPrice())

    setMintLoading(true)

    const contract = new library.eth.Contract(
      UGContractABI,
      UG_CONTRACT_ADDRESS
    );

    try {
      const receipt = await contract.methods
        .mint(account, mintAmount)
        .send({
          from: account,
          value: mintPrice
        });

      setMintLoading(false);
    } catch (error) {
      alert(error.message);
      setMintLoading(false);
      console.error('CONTRACT SET ERROR: ', error)
    }
  }

  const calculatePublicPrice = async () => {
    return (mintAmount * 40000000000000000)
  }

  const calculateWhitelistPrice = async () => {
    if (!oneFreeClaimed) {
      return (mintAmount * 20000000000000000) - 20000000000000000
    } else {
      return mintAmount * 20000000000000000
    }
  }

  const counterPlus = async () => {
    if (mintAmount >= 1 && mintAmount <= 5 && mintAmount != 5)
      setMintAmount(mintAmount + 1)
    calculatePublicPrice()
  }

  const counterMinus = async () => {
    if (mintAmount != 1 && mintAmount <= 5)
      setMintAmount(mintAmount - 1)
    calculatePublicPrice()
  }

  const claimMatcha = async (e) => {
    e.preventDefault();

    console.log('click claim ', calculatedWhitelistedMatchaValue)

    if (ugBalance == 0) {
      return false;
    }

    setClaimLoading(true)

    const contract = new library.eth.Contract(
      UGContractABI,
      UG_CONTRACT_ADDRESS
    );

    try {
      const receipt = await contract.methods
        .claimMatchaReward().send({ from: account });

      setClaimLoading(false);
    } catch (error) {
      alert(error.message);
      setClaimLoading(false);
      console.error('CONTRACT SET ERROR: ', error)
    }
  }

  const joinMatcha = async (e) => {
    e.preventDefault();

    setClaimLoading(true)

    const contract = new library.eth.Contract(
      UGContractABI,
      UG_CONTRACT_ADDRESS
    );

    try {
      const receipt = await contract.methods
        .joinMatchaReward().send({ from: account });

      setClaimLoading(false);
    } catch (error) {
      alert(error.message);
      setClaimLoading(false);
      console.error('CONTRACT SET ERROR: ', error)
    }
  }

  function calcFiveAPR() {
    let apr = Web3.utils.fromWei(currentSingleAPR.toString())
    return apr * 5;
  }

  return (

    <div className='home'>
      <div className='container-fluid'>
        <div className='home-header'>
          <div className='row'>
            <div className='col-md-6'>
              <a href='https://www.unimpressedgeisha.com/'><img src={UG_logo} /></a>
            </div>
            <div className='col-md-6 header-comp-matcha-balance'>
              {active ?
                <>
                  <div className='matcha-balance-header'>
                    $Matcha
                  </div>
                  <div className='matcha-balance-data'>
                    {parseFloat(matchaBalance).toFixed(4)}
                  </div>
                </>
                :
                <button className='home-header-btn' onClick={() => connectWallet()}>
                  {'Connect wallet'}
                </button>
              }
            </div>
          </div>
        </div>

        <div className='home-body'>
          <div className='row'>
            <div className='col-md-6 np'>
              <div className='home-body-left'>

              </div>
            </div>
            <div className='col-md-6'>
              <div className='home-body-right'>
                <div className='home-component-navi'>
                  <a target="_blank" href='https://www.unimpressedgeisha.com/'>{`< back to homepage`}</a>
                </div>

                {false &&
                  <div className='home-body-inner'>
                    <div className='home-body-header'>
                      {/* <img src={UG_logo} /> */}
                      <h1>Teahouse Whitelist</h1>
                      <p>Get whitelisted in UG NFT contract.</p>
                      <p>Whitelisted users are eligible to mint <strong>one<br />free Geisha</strong> and max 4 more Geishas<br /> with 50% discounted price. </p>
                    </div>
                    <div className='home-body-form'>
                      <form>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">@Your Twitter</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            name="twitter"
                            placeholder="@johndoe"
                            onChange={handleChange}
                          />
                          <small id="emailHelp" className="form-text text-muted">We'll never share your Twitter name with anyone else.</small>
                        </div>
                      </form>
                      <div className='home-body-form-status'>
                        {!active ?
                          <button className='home-mint-btn' onClick={() => connectWallet()}>
                            Connect Wallet
                          </button>
                          :
                          null
                        }
                        {active && isWhitelisted ?
                          <>
                            <div className='home-body-form-status-label'>Wallet status:</div>
                            <div className='home-body-form-status-data-whitelisted'>{account.slice(0, 8) + '...'} Whitelisted</div>
                          </>
                          :
                          active ?
                            <>
                              <button className='home-mint-btn' onClick={whitelistUser}>
                                {whitelistingOpen ? 'Whitelist me' : 'Loading...'}
                              </button>
                              <br />
                              {whitelistLoading ? <>
                                <small>Hold on a sec...</small>
                                <br />
                                <img className='loading-gif' src={loadingGif} />
                              </> : null}
                              {/* {active && !whitelistingOpen ? <strong><small>Starts at 1.12.2022 - 6PM CET</small></strong> : null} */}
                            </>
                            :
                            null
                        }
                      </div>
                    </div>
                  </div>
                }

                <div className='home-body-inner'>
                  <div className='home-body-header'>
                    {/* <img src={UG_logo} /> */}
                    <h1>Sale is closed!</h1>
                    <br/>
                    <p>Feel free to come back<br/>from time to time <strong>to claim your earned $matcha</strong></p>
                    <br/>
                    <p>...and remember, <strong>don't loose your minting wallet.</strong></p>
                    <br />
                    <p>Your minting wallet is important <strong>to claim your future ERC-20 $Matcha</strong></p>
                  </div>
                  <div className='home-body-form'>
                    {/* <div className='home-body-counter'>
                      <button onClick={() => counterMinus()} className='btn-counter'>-</button>
                      <div className='counter-data'><p>{mintAmount}</p></div>
                      <button onClick={() => counterPlus()} className='btn-counter'>+</button>
                    </div> */}
                    <br />
                    <div className='home-body-price'>
                      <div className='home-body-price-data'>
                        <p>Your claimable balance:</p>
                        <br />
                        <p>
                          <strong>
                            {/* {!oneFreeClaimed ?
                              (mintAmount >= 2 ? `${parseFloat((mintAmount * 0.04) - 0.02).toFixed(2)} Eth` : 'Free')
                              :
                              `${parseFloat(mintAmount * 0.02).toFixed(2)} Eth`
                            } */}
                            {
                              `${parseFloat(matchaBalance).toFixed(4)} $Matcha`
                            }
                          </strong>
                        </p></div>
                    </div>
                    {!active ?
                      <button className='home-mint-btn' onClick={() => connectWallet()}>
                        Connect Wallet
                      </button>
                      :
                      null
                    }
                    {active &&
                      <button className='home-mint-btn' onClick={publicMint}>{saleOpen ? 'Mint' : 'Sale is closed'}</button>
                    }
                    <br />
                    {/* {mintLoading ? <>
                      <small>Hold on a sec...</small>
                      <br />
                      <img className='loading-gif' src={loadingGif} />
                    </> : null} */}
                    {/* {active ?
                      (isWhitelisted ?
                        <small>Hurray!<br />Your wallet is among whitelisted addresses!</small>
                        :
                        <small>Sorry, this is not whitelisted wallet.</small>)
                      : null
                    } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='home-matcha'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='matcha-section'>
              <h1>Earn $Matcha</h1>
              {/* <p>Earn Matcha Points just by whitelisting your wallet<br />and holding your Geisha.</p> */}
              {/* <p><strong>+ 50 $Matcha for whitelisting</strong></p> */}
              <p>Holders of UG NFTs are able to claim earned tokens which will be exchanged 1:1 for ERC-20 $Matcha tokens in the future.</p>
              <div className='matcha-section-box'>
                <div className='matcha-section-balance-title'>Earned so far:</div>
                <div className='matcha-section-balance'>
                  <div className='matcha-section-ticker-apr'>
                    {ugBalance > 0 ?
                      `+${Web3.utils.fromWei(calculatedAPR.toString())}%`
                      :
                      `+${Web3.utils.fromWei(currentSingleAPR.toString())}%`
                    }
                    {/* {`+${Web3.utils.fromWei(currentSingleAPR.toString())}%`} */}
                  </div>
                  <div className='matcha-section-ticker'>
                    $Matcha
                  </div>
                  <div className='matcha-section-balance-data'>
                    {ugBalance >= 1 ?
                      parseFloat(calculateEarnedMatchaReward).toFixed(4)
                      :
                      calculatedWhitelistedMatchaValue
                    }
                  </div>
                </div>
              </div>
              <div className='matcha-claim'>
                {active ?
                  (isJoined !== '0' ? <button onClick={claimMatcha} className='matcha-claim-btn'>Claim</button> : <button onClick={joinMatcha} className='matcha-claim-btn'>Join $Matcha Rewards</button>)
                  :
                  <button onClick={() => connectWallet()} className='matcha-claim-btn'>Connect wallet</button>
                }
                <br />
                {claimLoading ? <>
                  <small>Hold on a sec...</small>
                  <br />
                  <img className='loading-gif' src={loadingGif} />
                </> : null}
                {ugBalance >= 1 ?
                  null
                  :
                  <>
                    <small>Only holders of UG NFTs are able to claim earned $Matcha.</small>
                    <br />

                  </>
                }
                {active &&
                  <small><strong>{`You own ${ugBalance} UG NFTs`}</strong></small>
                }
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='home-matcha-left'>
              <img src={matcha} />
              <p>You own <strong>{`${ugBalance} UG NFT`}</strong></p>
              {/* <p><strong>{`Earning +${Web3.utils.fromWei(calculatedAPR.toString())}% $Matcha APR`}</strong></p> */}
              <p>Current APR for 5 UG NFTs: <br /><span className='calc-apr'><strong>{`+${calcFiveAPR()}%`}</strong></span></p>
              {/* <p>You receive only whitelisting bonus.</p> */}
              <p>Double Geishas means<br /><strong>double APR.</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div className='home-watch'>
        <div className='home-yt'>
          <iframe width="1000" height="600" src="https://www.youtube.com/embed/hGwxzwuUlaA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-navi'>
          <a href='https://bit.ly/34VLt3q'>INSTAGRAM</a>
          <a href='https://discord.gg/hcSawTXwEn'>DISCORD</a>
          <a href='https://bit.ly/3oNu8RE'>TWITTER</a>
        </div>
        <div className='footer-made-by'>
          <p>Made by <a href='https://bit.ly/3JrjqYH'>Crea Crea Studio</a> and <a href='https://droplove.xyz/'>Droplove.xyz</a></p>
        </div>
      </div>
    </div >
  );
}

export default Home;
